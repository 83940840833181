import { Trigger } from '@radix-ui/react-dropdown-menu';
import { FunctionComponent } from 'react';
import { Button } from '../../../../../../components/Button';
import { UserCircleRegularIcon } from '../../../../../../components/Icons/UserCircleRegularIcon';
import { AngleDownRegularIcon } from '../../../../../../components/Icons/AngleDownRegularIcon';
import { useIam } from '../../../../../../services/IAm/hooks';
import styles from './styles.module.scss';

export type UserDropdownTriggerProps = {};

export const UserDropdownTrigger: FunctionComponent<UserDropdownTriggerProps> = () => {
    const { iam } = useIam();

    return (
        <Trigger asChild>
            <Button
                className={styles.trigger}
                data-authenticated={iam.authenticated || undefined}
                preset={iam.authenticated ? 'primary' : 'textButton'}
            >
                <UserCircleRegularIcon width="20" />
                <span>My Account</span>
                <AngleDownRegularIcon width="12" />
            </Button>
        </Trigger>
    );
};
