import { FunctionComponent, useMemo } from 'react';
import { Item } from '@radix-ui/react-dropdown-menu';
import styles from '../styles.module.scss';
import Link from 'next/link';
import { usePathname, useSearchParams } from 'next/navigation';
import { UserCircleLightIcon } from '../../../../../../../components/Icons/UserCircleLightIcon';

export type UnauthenticatedUserDropdownContentProps = {
    educatorId?: string;
};

export const UnauthenticatedUserDropdownContent: FunctionComponent<
    UnauthenticatedUserDropdownContentProps
> = ({ educatorId }) => {
    const pathname = usePathname();
    const searchParams = useSearchParams();

    const loginUrl = useMemo(() => {
        const path = `${process.env.NEXT_PUBLIC_MARKETPLACE_URL}/login`;
        const nextSearchParams = new URLSearchParams();

        if (pathname && searchParams) {
            nextSearchParams.set(
                'redirect',
                new URL(`${pathname}?${searchParams}`, window.location.href).toString()
            );
        }

        if (educatorId) {
            nextSearchParams.set('educator', educatorId);
        }

        return `${path}?${nextSearchParams.toString()}`;
    }, [pathname, searchParams, educatorId]);

    return (
        <>
            <Item className={styles.dropdownItem} asChild>
                <Link href={loginUrl}>
                    <UserCircleLightIcon width="20" />
                    <span>Log in or Sign Up</span>
                </Link>
            </Item>
        </>
    );
};
