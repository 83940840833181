import { FunctionComponent, Suspense } from 'react';
import { Content, Arrow } from '@radix-ui/react-dropdown-menu';
import { useIam } from '../../../../../../services/IAm/hooks';
import styles from './styles.module.scss';
import { AuthenticatedUserDropdownContent } from './AuthenticatedContent';
import { UnauthenticatedUserDropdownContent } from './UnauthenticatedContent';

export type UserDropdownContentProps = {
    educatorId?: string;
};

export const UserDropdownContent: FunctionComponent<UserDropdownContentProps> = ({
    educatorId,
}) => {
    const { iam } = useIam();

    return (
        <Content className={styles.dropdownContent} collisionPadding={20}>
            {/* https://nextjs.org/docs/messages/missing-suspense-with-csr-bailout */}
            <Suspense>
                {iam.authenticated ? (
                    <AuthenticatedUserDropdownContent iam={iam} />
                ) : (
                    <UnauthenticatedUserDropdownContent educatorId={educatorId} />
                )}
            </Suspense>
            <Arrow className={styles.containerArrow} />
        </Content>
    );
};
