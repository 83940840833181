import { Category } from '@shared/types';
import { useQuery, QueryFunction, QueryClient, UseQueryOptions } from '@tanstack/react-query';
import { useMemo } from 'react';

const queryKey = 'POST /categories/bulk';
type CategoryReferences = {
    category_refs: Array<Category['_id']>;
    category_slugs: Array<Category['slug']>;
};
type QueryKeys = [typeof queryKey, CategoryReferences];

const categoryReferencesToQueryKey = (categoryReferences: CategoryReferences): QueryKeys => {
    const sortedRefs = [...categoryReferences.category_refs].sort();
    const sortedSlugs = [...categoryReferences.category_slugs].sort();
    return [
        'POST /categories/bulk',
        {
            category_refs: sortedRefs,
            category_slugs: sortedSlugs,
        },
    ];
};

export const getCategories = async (
    categoryReferences: CategoryReferences
): Promise<Array<Category>> => {
    if (
        categoryReferences.category_refs.length === 0 &&
        categoryReferences.category_slugs.length === 0
    ) {
        return [];
    }

    const response = await fetch(`${process.env.NEXT_PUBLIC_CADEMY_API_URL}/categories/bulk`, {
        method: 'POST',
        body: JSON.stringify(categoryReferences),
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
    });
    if (!response.ok) {
        const error = await response.text();
        throw new Error(error);
    }
    return response.json();
};

const getCategoriesQueryFunction: QueryFunction<Array<Category>, QueryKeys> = ({ queryKey }) => {
    const [_, categoryReferences] = queryKey;
    return getCategories(categoryReferences);
};

export type UseCategoriesOptions = Omit<
    UseQueryOptions<Array<Category>, unknown, Array<Category>, QueryKeys>,
    'queryKey' | 'queryFn'
>;
export const useCategories = (
    categoryReferences: CategoryReferences,
    options?: UseCategoriesOptions
) => {
    const queryKey = useMemo(() => {
        return categoryReferencesToQueryKey(categoryReferences);
    }, [categoryReferences]);
    return useQuery({
        queryKey,
        queryFn: getCategoriesQueryFunction,
        ...options,
    });
};

export const preFetchCategories = async (
    queryClient: QueryClient,
    categoryReferences: CategoryReferences
) => {
    const queryKey = categoryReferencesToQueryKey(categoryReferences);
    return queryClient.prefetchQuery(queryKey, getCategoriesQueryFunction);
};
