'use client';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { FunctionComponent } from 'react';
import { UserDropdownTrigger } from './Trigger';
import { UserDropdownContent } from './Content';

export type UserDropdownProps = {
    educatorId?: string;
};

export const UserDropdown: FunctionComponent<UserDropdownProps> = ({ educatorId }) => {
    return (
        <DropdownMenu.Root modal={false}>
            <UserDropdownTrigger />
            <DropdownMenu.Portal>
                <UserDropdownContent educatorId={educatorId} />
            </DropdownMenu.Portal>
        </DropdownMenu.Root>
    );
};
