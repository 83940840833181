import { useEffect, useRef, useState } from 'react';

export const useDebouncedValue = <Type>(value: Type, delay: number): Type => {
    const [debouncedValue, setDebouncedValue] = useState<Type>(value);
    const previousValueRef = useRef<Type>(value);
    useEffect(() => {
        const timeoutRef = setTimeout(() => {
            if (JSON.stringify(previousValueRef.current) === JSON.stringify(value)) {
                return;
            }
            previousValueRef.current = value;
            setDebouncedValue(value);
        }, delay);
        return () => clearTimeout(timeoutRef);
    }, [value, delay]);
    return debouncedValue;
};
