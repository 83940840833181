import { useEffect, useRef } from 'react';

/**
 * Ensures a callback is only called when a value changes not when a callback changes
 * @param value
 * @param callback
 */
export const useOnValueChanged = <Type>(value: Type, callback: (type: Type) => any) => {
    const previousValue = useRef<null | Type>(null);
    useEffect(() => {
        if (previousValue.current !== value) {
            callback(value);
            previousValue.current = value;
        }
    }, [value, callback]);
};
