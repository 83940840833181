import Link from 'next/link';
import Image from 'next/image';
import { FunctionComponent, Suspense } from 'react';
import { PrimaryCtaLink } from './PrimaryCtaLink';
import { UserDropdown } from './UserDropdown';
import { TwitterXIcon } from '../../../../components/Icons/TwitterXIcon';
import { FacebookIcon } from '../../../../components/Icons/FacebookIcon';
import { LinkedinIcon } from '../../../../components/Icons/LinkedinIcon';
import { EnvelopeOpenIcon } from '../../../../components/Icons/EnvelopeOpenIcon';
import styles from './styles.module.scss';
import SearchBar from '../../../../pages_components/home/SearchBar';

type MarketplaceShellHeaderProps = {
    showSearchBar?: boolean;
};

export const MarketplaceShellHeader: FunctionComponent<MarketplaceShellHeaderProps> = ({
    showSearchBar = true,
}) => {
    return (
        <header className={styles.header}>
            <div className={styles.header_container}>
                <div className={styles.cademy_header_logo}>
                    <Link href="/">
                        <Image
                            src={`${process.env.NEXT_PUBLIC_ASSETS_URL}/logos/logo-resized.svg`}
                            alt="Cademy logo"
                            width={184}
                            height={30}
                        />
                        <span className="screen_reader_hidden">Cademy Marketplace</span>
                    </Link>
                </div>
                {showSearchBar ? (
                    <div className={styles.searchBar}>
                        {/* https://nextjs.org/docs/messages/missing-suspense-with-csr-bailout */}
                        <Suspense>
                            <SearchBar />
                        </Suspense>
                    </div>
                ) : null}
                <nav>
                    {/* https://nextjs.org/docs/messages/missing-suspense-with-csr-bailout */}
                    <Suspense>
                        <PrimaryCtaLink className={styles.callToAction} />
                    </Suspense>
                    <UserDropdown />
                    <input
                        id="mobile-menu-toggle"
                        className={styles.menuCheckbox}
                        type="checkbox"
                    />
                    <label
                        className={styles.hamburgerButton}
                        htmlFor="mobile-menu-toggle"
                        role="button"
                        aria-haspopup="true"
                        aria-controls="mobile-nav"
                        aria-label="Menu toggle"
                    >
                        <span className={styles.hamburgerLine}></span>
                    </label>
                    <div className={styles.mobile_nav} id="mobile-nav">
                        <div>
                            <label id="mobile-browser-menu-label">Browse</label>
                            <ul aria-label="mobile-browser-menu-label">
                                <li>
                                    <Link href="/">Home</Link>
                                </li>
                                <li>
                                    <Link href="/for-educators">Features</Link>
                                </li>
                                <li>
                                    <Link href="/pricing">Pricing</Link>
                                </li>
                                <li>
                                    <Link href="/advertise">Advertise on Cademy</Link>
                                </li>
                                <li>
                                    <Link href="/about">About us</Link>
                                </li>
                                <li>
                                    <Link href="/contact">Contact us</Link>
                                </li>
                                <li>
                                    <Link
                                        href={`${process.env.NEXT_PUBLIC_CADEMY_ROADMAP_URL}/changelog`}
                                    >
                                        Product Updates
                                    </Link>
                                </li>
                            </ul>
                            <div className={styles.social_icons}>
                                <ul>
                                    <li>
                                        <Link
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="https://twitter.com/cademy__"
                                            aria-label="Twitter"
                                        >
                                            <TwitterXIcon />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="https://www.facebook.com/cademy.co.uk"
                                            aria-label="Facebook"
                                        >
                                            <FacebookIcon />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="https://www.linkedin.com/company/cademy-io"
                                            aria-label="LinkedIn"
                                        >
                                            <LinkedinIcon />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="https://upscri.be/hxh121"
                                            aria-label="Subscribe to email updates"
                                        >
                                            <EnvelopeOpenIcon />
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className={styles.made_with_love}>
                                <Link href="/about">Made with ❤️ in Edinburgh 🏴󠁧󠁢󠁳󠁣󠁴󠁿</Link>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    );
};
