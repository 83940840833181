import { MarketplaceTag, SystemTag } from '@shared/types';
import { useQuery, QueryFunction, QueryClient, UseQueryOptions } from '@tanstack/react-query';
import { useMemo } from 'react';

const queryKey = 'POST /marketplace_tags/bulk';
type TagReferences = {
    tag_refs: Array<MarketplaceTag['_id']>;
    tag_slugs: Array<SystemTag['slug']>;
};
type QueryKeys = [typeof queryKey, TagReferences];

const tagReferencesToQueryKey = (tagReferences: TagReferences): QueryKeys => {
    const sortedRefs = [...tagReferences.tag_refs].sort();
    const sortedSlugs = [...tagReferences.tag_slugs].sort();
    return [
        'POST /marketplace_tags/bulk',
        {
            tag_refs: sortedRefs,
            tag_slugs: sortedSlugs,
        },
    ];
};

export const getMarketplaceTags = async (
    tagReferences: TagReferences
): Promise<Array<MarketplaceTag>> => {
    if (tagReferences.tag_refs.length === 0 && tagReferences.tag_slugs.length === 0) {
        return [];
    }

    const response = await fetch(
        `${process.env.NEXT_PUBLIC_CADEMY_API_URL}/marketplace_tags/bulk`,
        {
            method: 'POST',
            body: JSON.stringify(tagReferences),
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        }
    );
    if (!response.ok) {
        const error = await response.text();
        throw new Error(error);
    }
    return response.json();
};

export const getMarketplaceTagsQueryFunction: QueryFunction<Array<MarketplaceTag>, QueryKeys> = ({
    queryKey,
}) => {
    const [_, tagReferences] = queryKey;
    return getMarketplaceTags(tagReferences);
};

export type UseMarketplaceTagsOptions = Omit<
    UseQueryOptions<Array<MarketplaceTag>, unknown, Array<MarketplaceTag>, QueryKeys>,
    'queryKey' | 'queryFn'
>;
export const useMarketplaceTags = (
    tagReferences: TagReferences,
    options?: UseMarketplaceTagsOptions
) => {
    const queryKey = useMemo(() => {
        return tagReferencesToQueryKey(tagReferences);
    }, [tagReferences]);
    return useQuery({
        queryKey,
        queryFn: getMarketplaceTagsQueryFunction,
        ...options,
    });
};

export const preFetchMarketplaceTags = async (
    queryClient: QueryClient,
    tagReferences: TagReferences
) => {
    const queryKey = tagReferencesToQueryKey(tagReferences);
    return queryClient.prefetchQuery(queryKey, getMarketplaceTagsQueryFunction);
};
