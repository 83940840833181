'use client';
import { FunctionComponent } from 'react';
import Link from 'next/link';
import { Button, ButtonProps } from '../Button';

export type ButtonLinkProps = ButtonProps<typeof Link>;

export const ButtonLink: FunctionComponent<ButtonLinkProps> = (props) => {
    if (props.disabled) {
        // Disabled links are not a thing so just render a regular disabled button
        return <Button {...props} />;
    }
    return <Button as={Link} {...props} />;
};

export default ButtonLink;
