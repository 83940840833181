'use client';
import { useState, FunctionComponent } from 'react';
import Image, { ImageProps } from 'next/image';

type ImageWithFallbackProps = ImageProps & {
    fallback: string;
};

export const ImageWithFallback: FunctionComponent<ImageWithFallbackProps> = ({
    fallback,
    alt,
    src,
    ...props
}) => {
    const [error, setError] = useState(false);

    return (
        <Image
            alt={alt}
            onError={() => {
                setError(true);
            }}
            src={error ? fallback : src}
            {...props}
        />
    );
};
