import { Educator } from '@shared/types';
import Link from 'next/link';
import { FunctionComponent, PropsWithChildren, useMemo, ComponentProps } from 'react';

export type AdminDashboardLinkProps = PropsWithChildren<{
    educatorId?: Educator['_id'];
    className?: string;
}> &
    Omit<ComponentProps<typeof Link>, 'href'>;

export const AdminDashboardLink: FunctionComponent<AdminDashboardLinkProps> = ({
    children,
    educatorId,
    className,
    ...linkProps
}) => {
    const href = useMemo(() => {
        const withContext = educatorId ? `?educator-context=${educatorId}` : '';

        return `${process.env.NEXT_PUBLIC_ADMIN_URL}${withContext}`;
    }, [educatorId]);
    return (
        <Link {...linkProps} className={className} href={href}>
            {children}
        </Link>
    );
};
