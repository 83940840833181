import { FunctionComponent, MutableRefObject, useCallback } from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';
import { SearchIndexAutoComplete } from '@shared/types';
import EducatorOption from './EducatorOption';
import Option from '../Option';
import useAsyncSelect, { OptionMapFunction } from '../useAsyncSelect';
import { useOnValueChanged } from '../../../../hooks/useOnValueChanged';
import { CademyError } from '@shared/domain-shared';
import { GeneralSearchError } from '../../../../services/search/errors';
import MagnifyingGlassLightIcon from '../../../../components/Icons/MagnifyingGlassLightIcon';
import { CloseSolidIcon } from '../../../../components/Icons';
import MagnifyingGlassRegularIcon from '../../../../components/Icons/MagnifyingGlassRegularIcon';

export type GeneralSearch = {
    type: 'search';
    name: string;
};

const INITIAL_OPTIONS: Array<SearchIndexAutoComplete> = [
    {
        _id: '',
        type: 'category',
        name: 'Professional Development',
        slug: 'professional-development',
        synonyms: [],
    },
    {
        _id: '',
        type: 'category',
        name: 'Medicine & Nursing',
        slug: 'medicine-nursing',
        synonyms: [],
    },
    {
        _id: '',
        type: 'category',
        name: 'Arts & Crafts',
        slug: 'arts-crafts',
        synonyms: [],
    },
    {
        _id: '',
        type: 'category',
        name: 'Health & Wellbeing',
        slug: 'health-wellbeing',
        synonyms: [],
    },
    {
        _id: '',
        type: 'category',
        name: 'Personal Development',
        slug: 'personal-development',
        synonyms: [],
    },
];

const queryFunction = async (
    search: string
): Promise<Array<SearchIndexAutoComplete | GeneralSearch>> => {
    if (!search) {
        return INITIAL_OPTIONS;
    }

    const response = await fetch(
        `${process.env.NEXT_PUBLIC_CADEMY_API_URL}/search/autocomplete?search=${encodeURIComponent(search)}`,
        { credentials: 'include' }
    );

    if (response.ok === false) {
        if (response.headers.get('content-type') === 'application/problem+json') {
            const problemDetails = await response.json();
            const error = CademyError.fromObject(problemDetails);
            throw error;
        }
        if (response.headers.get('content-type') === 'application/json') {
            const errorDetails = await response.json();
            throw new GeneralSearchError(errorDetails.safe);
        }
        const error = await response.text();
        console.error(new Error(`${response.status} - ${error}`));
        throw new GeneralSearchError();
    }

    const results = (await response.json()) as Array<SearchIndexAutoComplete>;
    const hasSearchTerm = search.trim() !== '';
    const resultsContainSearchTerm = results.some((result) => {
        return result.name.toLocaleLowerCase().trim() === search.toLocaleLowerCase().trim();
    });
    if (hasSearchTerm === true && resultsContainSearchTerm === false) {
        return [
            {
                type: 'search',
                name: search,
            },
            ...results,
        ];
    } else {
        return results;
    }
};

const getOptionDisplayValue = (option: SearchIndexAutoComplete | GeneralSearch) => {
    return option.name;
};

const optionToOptionElement: OptionMapFunction<SearchIndexAutoComplete | GeneralSearch> = (
    option,
    props,
    index
) => {
    switch (option.type) {
        case 'tag': {
            return <Option key={index} text={option.name} {...props} />;
        }
        case 'category': {
            return <Option key={index} text={option.name} {...props} />;
        }
        case 'search': {
            const text = `Search "${option.name}"`;
            return <Option key={index} text={text} {...props} />;
        }
        case 'educator': {
            return <EducatorOption key={index} educator={option} {...props} />;
        }
    }
};

const createOption = (search: string): GeneralSearch => {
    return {
        type: 'search',
        name: search,
    };
};

type PrimaryQuerySelectProps = {
    initialComboboxValue?: string;
    includeEducators?: boolean;
    onChange: (search: null | SearchIndexAutoComplete | GeneralSearch) => any;
    comboboxRef?: MutableRefObject<HTMLInputElement | null>;
    isFullWidth: boolean;
};

export const PrimaryQuerySelect: FunctionComponent<PrimaryQuerySelectProps> = ({
    initialComboboxValue,
    onChange,
    comboboxRef,
    isFullWidth,
}) => {
    const { selectedValue, comboboxProps, listboxProps, mapOptions, isListboxOpen } =
        useAsyncSelect<SearchIndexAutoComplete | GeneralSearch>({
            initialComboboxValue,
            options: INITIAL_OPTIONS,
            getDisplayValue: getOptionDisplayValue,
            createOption: createOption,
            queryFunction: queryFunction,
        });

    useOnValueChanged(selectedValue, onChange);

    const doSearch = useCallback(() => {
        if (selectedValue?.name === comboboxProps?.value) {
            onChange(selectedValue);
        } else {
            onChange({
                type: 'search',
                name: comboboxProps.value,
            });
        }
    }, [selectedValue, comboboxProps.value, onChange]);

    return (
        <>
            <div className={styles.comboboxContainer}>
                <MagnifyingGlassLightIcon className={styles.inputIcon} />
                <input
                    enterKeyHint="search"
                    aria-label="Area or subject of interest"
                    ref={comboboxRef}
                    className={classnames(styles.combobox, { [styles.fullWidth]: isFullWidth })}
                    placeholder="Search subject or interest"
                    {...comboboxProps}
                />

                {selectedValue || initialComboboxValue ? (
                    <button
                        onClick={() => onChange({ type: 'search', name: '' })}
                        className={styles.clearIcon}
                    >
                        <CloseSolidIcon />
                    </button>
                ) : null}
                <button className={styles.searchSubmitIcon} onClick={doSearch}>
                    <MagnifyingGlassRegularIcon />
                </button>
            </div>
            <ul
                aria-label="Area or subject options"
                className={classnames(styles.listbox, { [styles.open]: isListboxOpen })}
                {...listboxProps}
            >
                {mapOptions(optionToOptionElement)}
            </ul>
        </>
    );
};

export default PrimaryQuerySelect;
