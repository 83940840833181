import { DetailedHTMLProps, FunctionComponent, LiHTMLAttributes, ReactElement } from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';
import MagnifyingGlassLightIcon from '../../../../components/Icons/MagnifyingGlassLightIcon';

export type OptionProps = {
    text: string;
} & DetailedHTMLProps<LiHTMLAttributes<HTMLLIElement>, HTMLLIElement>;

export const Option: FunctionComponent<OptionProps> = ({ text, ...props }) => {
    return (
        <li
            {...props}
            className={classnames(
                styles.option,
                { [styles.focussed]: props['aria-selected'] },
                props.className
            )}
        >
            <div className={styles.optionLogo}>
                <MagnifyingGlassLightIcon style={{ width: '0.9em' }} />
            </div>
            <div>{text}</div>
        </li>
    );
};

export default Option;
