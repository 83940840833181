import { useEffect, useReducer } from 'react';

export type Action<OptionType> =
    | { type: 'Combobox was focussed' }
    | { type: 'Combobox lost focus' }
    | { type: 'Down Arrow was pressed' }
    | { type: 'Up Arrow was pressed' }
    | { type: 'Enter was pressed' }
    | { type: 'Escape was pressed' }
    | { type: 'Combobox text was changed'; value: string }
    | { type: 'Combobox value was set'; value: string }
    | { type: 'Combobox was cleared' }
    | { type: 'Options were loaded'; newOptions: Array<OptionType> }
    | { type: 'Option was clicked'; index: number };

type BaseSelectState<OptionType> = {
    focussedIndex: number | null;
    options: Array<OptionType>;
    comboboxValue: string;
    selectedValue: null | OptionType;
    getDisplayValue: (option: OptionType) => string;
    createOption?: (search: string) => OptionType;
};
type ClosedSelectState<OptionType> = BaseSelectState<OptionType> & { isListboxOpen: false };
type OpenSelectState<OptionType> = BaseSelectState<OptionType> & { isListboxOpen: true };
export type SelectState<OptionType> = ClosedSelectState<OptionType> | OpenSelectState<OptionType>;

const wrapBetween = (minimum: number, maximimum: number, value: number) => {
    if (value < minimum) {
        return maximimum;
    }
    if (value > maximimum) {
        return minimum;
    }
    return value;
};

const closedSelectReducer = <OptionType>(
    state: ClosedSelectState<OptionType>,
    action: Action<OptionType>
): SelectState<OptionType> => {
    switch (action.type) {
        case 'Combobox was focussed': {
            return {
                ...state,
                isListboxOpen: true,
            };
        }
        case 'Down Arrow was pressed': {
            return {
                ...state,
                isListboxOpen: true,
                focussedIndex: 0,
            };
        }
        case 'Up Arrow was pressed': {
            return {
                ...state,
                isListboxOpen: true,
                focussedIndex: state.options.length - 1,
            };
        }
        case 'Escape was pressed': {
            return {
                ...state,
                comboboxValue: '',
                selectedValue: null,
            };
        }
        case 'Enter was pressed': {
            return {
                ...state,
                comboboxValue: '',
                selectedValue: { name: '', type: '' } as OptionType,
            };
        }
    }
    return state;
};

const openSelectReducer = <OptionType>(
    state: OpenSelectState<OptionType>,
    action: Action<OptionType>
): SelectState<OptionType> => {
    switch (action.type) {
        case 'Combobox lost focus': {
            return { ...state, isListboxOpen: false };
        }
        case 'Down Arrow was pressed': {
            return {
                ...state,
                focussedIndex:
                    state.focussedIndex !== null
                        ? wrapBetween(0, state.options.length - 1, state.focussedIndex + 1)
                        : 0,
            };
        }
        case 'Up Arrow was pressed': {
            return {
                ...state,
                focussedIndex:
                    state.focussedIndex !== null
                        ? wrapBetween(0, state.options.length - 1, state.focussedIndex - 1)
                        : state.options.length - 1,
            };
        }
        case 'Enter was pressed': {
            if (state.focussedIndex === null && state.comboboxValue === '') {
                return {
                    ...state,
                    isListboxOpen: false,
                    comboboxValue: '',
                    selectedValue: { name: '', type: '' } as OptionType,
                };
            }
            if (state.focussedIndex === null && state.createOption) {
                const newOption = state.createOption(state.comboboxValue);
                return {
                    ...state,
                    isListboxOpen: false,
                    comboboxValue: state.getDisplayValue(newOption),
                    selectedValue: newOption,
                };
            }
            const indexToSet = state.focussedIndex !== null ? state.focussedIndex : 0;
            return {
                ...state,
                isListboxOpen: false,
                comboboxValue: state.getDisplayValue(state.options[indexToSet]),
                selectedValue: state.options[indexToSet],
            };
        }
        case 'Option was clicked': {
            return {
                ...state,
                isListboxOpen: false,
                comboboxValue: state.getDisplayValue(state.options[action.index]),
                focussedIndex: null,
                selectedValue: state.options[action.index],
            };
        }
        case 'Escape was pressed': {
            return {
                ...state,
                isListboxOpen: false,
            };
        }
    }
    return state;
};

const selectReducer = <OptionType>(
    state: SelectState<OptionType>,
    action: Action<OptionType>
): SelectState<OptionType> => {
    switch (action.type) {
        case 'Combobox text was changed': {
            return {
                ...state,
                isListboxOpen: true,
                comboboxValue: action.value,
            };
        }
        case 'Combobox value was set': {
            return {
                ...state,
                comboboxValue: action.value,
            };
        }
        case 'Combobox was cleared': {
            return {
                ...state,
                comboboxValue: '',
                selectedValue: null,
            };
        }
        case 'Options were loaded': {
            return {
                ...state,
                options: action.newOptions,
                focussedIndex: null,
            };
        }
    }
    if (state.isListboxOpen === true) {
        return openSelectReducer(state, action);
    } else {
        return closedSelectReducer(state, action);
    }
};

export const useAsyncSelectReducer = <OptionType>(
    initialComboboxValue: string = '',
    options: Array<OptionType>,
    getDisplayValue: (option: OptionType) => string,
    createOption?: (search: string) => OptionType
) => {
    const [state, dispatch] = useReducer<typeof selectReducer<OptionType>>(selectReducer, {
        focussedIndex: null,
        options: options,
        comboboxValue: initialComboboxValue,
        selectedValue: null,
        isListboxOpen: false,
        getDisplayValue: getDisplayValue,
        createOption: createOption,
    });

    useEffect(() => {
        if (!initialComboboxValue) {
            dispatch({ type: 'Combobox was cleared' });
            return;
        }
        dispatch({ type: 'Combobox value was set', value: initialComboboxValue });
    }, [initialComboboxValue]);

    return [state, dispatch] as const;
};
