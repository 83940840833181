import { Analytics, JestMock, LinkTypes } from '@shared/analytics';
import { useContext } from 'react';
import { AnalyticsContext } from '../../../contexts/AnalyticsContext';

export type RecordRedirect = (linkType: LinkTypes, href: URL) => Promise<void>;

export const useAnalytics = (): Analytics | JestMock => {
    const { analytics } = useContext(AnalyticsContext);

    return analytics;
};
