import { CademyError } from '@shared/domain-shared';
import { QueryFunction, useQuery } from '@tanstack/react-query';
import {
    useCallback,
    useId,
    KeyboardEvent,
    ReactElement,
    ChangeEvent,
    useEffect,
    MouseEvent,
    FocusEvent,
    useMemo,
    Dispatch,
    useState,
    useRef,
} from 'react';
import { useDebouncedValue } from '../../../../hooks/useDebouncedValue';
import { Action, SelectState, useAsyncSelectReducer } from './useAsyncSelectReducer';

export type OptionMapFunction<OptionType> = (
    option: OptionType,
    optionProps: {
        id: string;
        role: 'option';
        'aria-selected': boolean;
        onClick: (event: MouseEvent<HTMLElement, globalThis.MouseEvent>) => void;
    },
    index: number
) => ReactElement;

export type useAsyncSelectOptions<OptionType> = {
    initialComboboxValue?: string;
    options: Array<OptionType>;
    getDisplayValue: (option: OptionType) => string;
    createOption?: (search: string) => OptionType;
    queryFunction: (searchValue: string) => Array<OptionType> | Promise<Array<OptionType>>;
};

export type useAsyncSelectResult<OptionType> = {
    isLoadingOptions: boolean;
    loadOptionsError: null | CademyError;
    selectedValue: null | OptionType;
    isListboxOpen: boolean;
    comboboxProps: {
        id: string;
        role: 'combobox';
        'aria-autocomplete': 'list';
        'aria-controls': string;
        'aria-expanded': boolean;
        'aria-activedescendant': string;
        value: string;
        onKeyDown: (event: KeyboardEvent<HTMLElement>) => void;
        onChange: (event: ChangeEvent<HTMLInputElement>) => void;
        onClick: (event: MouseEvent<HTMLElement, globalThis.MouseEvent>) => void;
        onBlur: () => void;
        onFocus: () => void;
    };
    listboxProps: {
        id: string;
        role: 'listbox';
        onMouseDown: (event: MouseEvent<HTMLElement, globalThis.MouseEvent>) => void;
    };
    mapOptions: (mapFunction: OptionMapFunction<OptionType>) => Array<ReactElement>;
    setOptions: (newOptions: Array<OptionType>) => void;
    openListbox: () => void;
};

export class GeneralSelectError extends CademyError {
    constructor() {
        super(
            'web/select-search/general-error',
            'Select search failed',
            'There was an issue filtering your options in the field',
            500
        );
    }
}

const useComboboxProps = <OptionType,>(
    id: string,
    state: SelectState<OptionType>,
    dispatch: Dispatch<Action<OptionType>>
): useAsyncSelectResult<OptionType>['comboboxProps'] => {
    const onComboboxKeyDown = useCallback(
        (event: KeyboardEvent<HTMLElement>) => {
            const { key } = event;
            switch (key) {
                case 'ArrowDown': {
                    dispatch({ type: 'Down Arrow was pressed' });
                    event.preventDefault();
                    break;
                }
                case 'ArrowUp': {
                    dispatch({ type: 'Up Arrow was pressed' });
                    event.preventDefault();
                    break;
                }
                case 'Enter': {
                    dispatch({ type: 'Enter was pressed' });
                    break;
                }
                case 'Escape': {
                    dispatch({ type: 'Escape was pressed' });
                    break;
                }
            }
        },
        [dispatch]
    );

    const onComboboxChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            if (!event.target.value) {
                dispatch({
                    type: 'Combobox was cleared',
                });
            } else {
                dispatch({
                    type: 'Combobox text was changed',
                    value: event.target.value,
                });
            }
        },
        [dispatch]
    );

    const onComboboxClick = useCallback(() => {
        dispatch({ type: 'Combobox was focussed' });
    }, [dispatch]);

    const onComboboxBlur = useCallback(() => {
        dispatch({ type: 'Combobox lost focus' });
    }, [dispatch]);

    const onComboboxFocus = useCallback(() => {
        dispatch({ type: 'Combobox was focussed' });
    }, [dispatch]);

    const props: useAsyncSelectResult<OptionType>['comboboxProps'] = useMemo(() => {
        return {
            id: `${id}_combobox`,
            role: 'combobox',
            'aria-autocomplete': 'list',
            'aria-controls': `${id}_listbox`,
            'aria-expanded': state.isListboxOpen,
            'aria-activedescendant':
                state.isListboxOpen && state.focussedIndex !== null
                    ? `${id}_option_${state.focussedIndex}`
                    : '',
            value: state.comboboxValue,
            onKeyDown: onComboboxKeyDown,
            onChange: onComboboxChange,
            onClick: onComboboxClick,
            onBlur: onComboboxBlur,
            onFocus: onComboboxFocus,
        };
    }, [
        id,
        state,
        onComboboxKeyDown,
        onComboboxChange,
        onComboboxClick,
        onComboboxBlur,
        onComboboxFocus,
    ]);

    return props;
};

export const useAsyncSelect = <OptionType,>({
    initialComboboxValue,
    options,
    getDisplayValue,
    createOption,
    queryFunction,
}: useAsyncSelectOptions<OptionType>): useAsyncSelectResult<OptionType> => {
    const id = useId();

    const [state, dispatch] = useAsyncSelectReducer(
        initialComboboxValue,
        options,
        getDisplayValue,
        createOption
    );

    const useQueryFunction: QueryFunction<Array<OptionType>, [string, string]> = useCallback(
        async ({ queryKey }) => {
            const [_id, searchValue] = queryKey;
            return queryFunction(searchValue);
        },
        [queryFunction]
    );

    const queryValue = useDebouncedValue(state.comboboxValue, 150);

    const { data, isLoading, error } = useQuery({
        queryKey: [id, queryValue],
        queryFn: useQueryFunction,
        keepPreviousData: true,
    });

    const loadOptionsError = useMemo((): null | CademyError => {
        if (!error) {
            return null;
        }
        if (error instanceof CademyError) {
            return error;
        }
        return new GeneralSelectError();
    }, [error]);

    useEffect(() => {
        if (data) {
            dispatch({ type: 'Options were loaded', newOptions: data });
        }
    }, [data, dispatch]);

    const onListboxMouseDown = useCallback(
        (event: MouseEvent<HTMLElement, globalThis.MouseEvent>) => {
            event.preventDefault();
        },
        []
    );

    const mapOptions = useCallback(
        (mapFunction: OptionMapFunction<OptionType>): Array<ReactElement> => {
            return state.options.map((option, index) => {
                const focussed = index === state.focussedIndex;
                const onClick = () => {
                    dispatch({ type: 'Option was clicked', index });
                };
                return mapFunction(
                    option,
                    {
                        id: `${id}_option_${index}`,
                        role: 'option',
                        'aria-selected': focussed,
                        onClick: onClick,
                    },
                    index
                );
            });
        },
        [id, state.options, state.focussedIndex, dispatch]
    );

    const setOptions = useCallback(
        (newOptions: Array<OptionType>) => {
            dispatch({ type: 'Options were loaded', newOptions });
        },
        [dispatch]
    );

    const openListbox = useCallback(() => {
        dispatch({ type: 'Combobox was focussed' });
    }, [dispatch]);

    const comboboxProps = useComboboxProps(id, state, dispatch);

    return {
        isLoadingOptions: isLoading,
        loadOptionsError: loadOptionsError,
        selectedValue: state.selectedValue,
        isListboxOpen: state.isListboxOpen,
        comboboxProps: comboboxProps,
        listboxProps: {
            id: `${id}_listbox`,
            role: 'listbox',
            onMouseDown: onListboxMouseDown,
        },
        mapOptions: mapOptions,
        setOptions: setOptions,
        openListbox: openListbox,
    };
};

export default useAsyncSelect;
