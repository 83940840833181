// Copy pasted from https://github.com/catalinmiron/react-typical/blob/master/src/index.js
import React, { useRef, useEffect, memo, FunctionComponent, ReactNode } from 'react';
import { type, type as loopedType } from './typical';

import styles from './styles.module.scss';

type TypicalPropTypes = {
    steps: (string | number)[];
    loop?: string | number;
    className?: string;
    wrapper?: string;
};

const Typical: FunctionComponent<TypicalPropTypes> = ({ steps, loop, className, wrapper }) => {
    const typicalRef = useRef<ReactNode>(null);
    const Component: any = wrapper; //couldn't get TS to shut up otherwise
    const classNames = [styles.typicalWrapper];

    if (className) {
        classNames.unshift(className);
    }

    useEffect(() => {
        if (loop === 'Infinity') {
            type(typicalRef.current, ...steps, loopedType);
        } else if (typeof loop === 'number') {
            type(typicalRef.current, ...Array(loop).fill(steps).flat());
        } else {
            type(typicalRef.current, ...steps);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Component ref={typicalRef} className={classNames.join(' ')} />;
};

export default memo(Typical);
