'use client';
import Link, { LinkProps } from 'next/link';
import { useSearchParams } from 'next/navigation';
import { FunctionComponent, PropsWithChildren, useMemo } from 'react';

export type SignUpLinkProps = PropsWithChildren<Omit<LinkProps, 'href'>> & {
    className?: string;
    style?: React.CSSProperties;
};

const EDUCATOR_SIGN_UP_URL = process.env.NEXT_PUBLIC_EDUCATOR_SIGN_UP_URL;

const EducatorSignUpLink: FunctionComponent<SignUpLinkProps> = (props) => {
    const searchParams = useSearchParams();
    const referredBy = useMemo(() => searchParams?.get('rfrl'), [searchParams]);

    if (!EDUCATOR_SIGN_UP_URL) {
        throw new Error('Missing educator sign up url');
    }

    const signUpLinkWithReferal = useMemo<string>(() => {
        if (referredBy) {
            return `${EDUCATOR_SIGN_UP_URL}?rfrl=${referredBy}`;
        }

        return EDUCATOR_SIGN_UP_URL;
    }, [referredBy]);

    return (
        <Link {...props} href={signUpLinkWithReferal} rel="nofollow">
            {props.children}
        </Link>
    );
};

export default EducatorSignUpLink;
