import { DetailedHTMLProps, FunctionComponent, LiHTMLAttributes } from 'react';
import { SearchIndexEducatorAutoComplete } from '@shared/types';
import { ImageWithFallback } from '../../../../../components/ImageWithFallback';
import styles from './styles.module.scss';
import optionStyles from '../../Option/styles.module.scss';
import classnames from 'classnames';

export type EducatorOptionProps = {
    educator: SearchIndexEducatorAutoComplete;
} & DetailedHTMLProps<LiHTMLAttributes<HTMLLIElement>, HTMLLIElement>;

const fallback = `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/educator_fallback_logo.svg`;

export const EducatorOption: FunctionComponent<EducatorOptionProps> = ({ educator, ...props }) => {
    return (
        <li
            {...props}
            className={classnames(
                optionStyles.option,
                { [optionStyles.focussed]: props['aria-selected'] },
                props.className
            )}
        >
            <div className={styles.logo} aria-hidden={true}>
                <ImageWithFallback
                    alt={educator.name}
                    src={educator.image || fallback}
                    fallback={fallback}
                    fill={true}
                    sizes="24px"
                />
            </div>
            <div>{educator.name}</div>
        </li>
    );
};

export default EducatorOption;
