'use client';
import { SearchIndexAutoComplete } from '@shared/types';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import PrimaryQuerySelect, { GeneralSearch } from './PrimaryQuerySelect';
import styles from './styles.module.scss';
import { getCategories } from '../../../hooks/useCategories';
import { getMarketplaceTags } from '../../../hooks/useMarketplaceTags';
import { useAnalytics } from '../../../hooks/Analytics/useAnalytics';

export type SearchBarProps = {};

const getCategoryName = async (categorySlug: string): Promise<string> => {
    const categories = await getCategories({
        category_refs: [],
        category_slugs: [categorySlug],
    });
    return categories[0]?.name || '';
};

const getTagName = async (tagSlug: string): Promise<string> => {
    const tags = await getMarketplaceTags({
        tag_refs: [],
        tag_slugs: [tagSlug],
    });
    return tags[0]?.name || '';
};

const getCategoryOrTagName = async (categoryOrTagSlug: string): Promise<string> => {
    const [categoryName, tagName] = await Promise.all([
        getCategoryName(categoryOrTagSlug),
        getTagName(categoryOrTagSlug),
    ]);
    return categoryName || tagName || '';
};

const isCategoryOrTag = (val: string): boolean => {
    const [key] = val.split('-');
    return ['delivered', 'about', 'with', 'in', 'near', 'category'].includes(key) === false;
};

export const SearchBar: FunctionComponent<SearchBarProps> = () => {
    const analytics = useAnalytics();
    const primaryQueryComboboxRef = useRef<HTMLInputElement | null>(null);
    const [initialValue, setInitialValue] = useState<string>('');

    const router = useRouter();
    const path = usePathname();

    const searchParams = useSearchParams();
    const q = searchParams?.get('q');

    useEffect((): void => {
        if (q) {
            setInitialValue(decodeURIComponent(q));
            return;
        }
        if (path) {
            if (!path) return;
            if (typeof path !== 'string') return;
            const [_, courses, possibleTagOrCategory] = path.split('/');
            if (
                courses !== 'courses' ||
                !possibleTagOrCategory ||
                !isCategoryOrTag(possibleTagOrCategory)
            ) {
                setInitialValue('');
                return;
            }
            const fetchInitialValue = async () => {
                const tagOrCategoryName = await getCategoryOrTagName(possibleTagOrCategory);
                return tagOrCategoryName;
            };
            fetchInitialValue().then(setInitialValue);
        }
    }, [path, q]);

    const goToCoursesPage = useCallback(
        async (primaryQuery: SearchIndexAutoComplete | GeneralSearch | null) => {
            if (!primaryQuery) {
                return;
            }
            if (primaryQuery.type === 'educator') {
                return router.push(`/${primaryQuery.slug}`);
            }
            await analytics.record.event(`Search Bar: ${primaryQuery.type} selected`, {
                primary_query_type: primaryQuery.type,
                primary_query_name: primaryQuery.name,
                primary_query_slug:
                    primaryQuery.type === 'category' || primaryQuery.type === 'tag'
                        ? primaryQuery.slug
                        : null,
                search_string: primaryQuery.type === 'search' ? primaryQuery.name : null,
            });
            if (primaryQuery.type === 'category') {
                return router.push(`/courses/${primaryQuery.slug}`);
            }
            if (primaryQuery.type === 'tag') {
                return router.push(`/courses/${primaryQuery.slug}`);
            }
            router.push(`/courses?q=${encodeURIComponent(primaryQuery.name)}`);
        },
        [router, analytics]
    );

    return (
        <div className={styles.container}>
            <PrimaryQuerySelect
                onChange={goToCoursesPage}
                comboboxRef={primaryQueryComboboxRef}
                isFullWidth={true}
                initialComboboxValue={initialValue}
            />
        </div>
    );
};

export default SearchBar;
