import { Label, Item, Separator } from '@radix-ui/react-dropdown-menu';
import { AuthenticatedIAm } from '@shared/types';
import Link from 'next/link';
import { FunctionComponent } from 'react';
import { AdminDashboardLink } from '../../../../../../../components/Links/AdminDashboardLink';
import { EducatorAccountImage } from '../../../../../../../components/EducatorAccountImage';
import { UserCircleRegularIcon } from '../../../../../../../components/Icons/UserCircleRegularIcon';
import { UserGearRegularIcon } from '../../../../../../../components/Icons/UserGearRegularIcon';
import { ArrowRightFromBracketRegularIcon } from '../../../../../../../components/Icons/ArrowRightFromBracketRegularIcon';
import styles from '../styles.module.scss';
import HeartRegularIcon from '../../../../../../../components/Icons/HeartRegularIcon';

export type AuthenticatedUserDropdownContentProps = {
    iam: AuthenticatedIAm;
};

export const AuthenticatedUserDropdownContent: FunctionComponent<
    AuthenticatedUserDropdownContentProps
> = ({ iam }) => {
    const studentSectionLabel = iam.educators.length ? 'Student Profile' : 'User Profile';

    return (
        <>
            {iam.educators.length ? (
                <>
                    <Label className={styles.dropdownLabel}>Admin Dashboard</Label>
                    {iam.educators.map((educator) => {
                        return (
                            <Item key={educator.id} className={styles.dropdownItem} asChild>
                                <AdminDashboardLink
                                    educatorId={educator.id}
                                    target="_blank"
                                    rel="noopener"
                                >
                                    <EducatorAccountImage
                                        src={educator.logo}
                                        size={20}
                                        alt={`${educator.name} logo`}
                                        className={styles.educatorLogo}
                                    />
                                    <span>{educator.name}</span>
                                </AdminDashboardLink>
                            </Item>
                        );
                    })}
                    <Separator className={styles.separator} />
                </>
            ) : null}
            <Label className={styles.dropdownLabel}>{studentSectionLabel}</Label>
            <Item className={styles.dropdownItem} asChild>
                <Link href="/profile">
                    <UserCircleRegularIcon width="20" />
                    <span>My Courses & Bookings</span>
                </Link>
            </Item>
            <Item className={styles.dropdownItem} asChild>
                <Link href="/profile/saved-courses">
                    <HeartRegularIcon height="16" width="20" />
                    <span>Saved</span>
                </Link>
            </Item>
            <Item className={styles.dropdownItem} asChild>
                <Link href="/profile/settings">
                    <UserGearRegularIcon width="20" />
                    <span>User Settings</span>
                </Link>
            </Item>
            <Separator className={styles.separator} />
            <Item className={styles.dropdownItem} asChild>
                <Link href="/logout" className={styles.logout}>
                    <ArrowRightFromBracketRegularIcon width="20" />
                    <span>Log out</span>
                </Link>
            </Item>
        </>
    );
};
