import React, { FunctionComponent } from 'react';
import styles from './styles.module.css';
import classNames from 'classnames';
import Image from 'next/image';

const DEFAULT_URL = `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/educator_fallback_logo.svg`;

const DEFAULT_SIZE = 16;

type AccountImageProps = {
    src?: string;
    className?: string;
    size?: number;
    alt: string;
};

export const EducatorAccountImage: FunctionComponent<AccountImageProps> = ({
    src,
    className,
    alt,
    size = DEFAULT_SIZE,
}) => {
    if (!src) {
        return (
            <div className={classNames(styles.accountLogo, className)}>
                <Image
                    className={styles.defaultImage}
                    src={DEFAULT_URL}
                    alt="Default educator logo"
                    width={size}
                    height={size}
                />
                ;
            </div>
        );
    }

    return (
        <div className={classNames(styles.accountLogo, className)}>
            <Image className={styles.educatorImage} src={src} alt={alt} fill />
        </div>
    );
};
